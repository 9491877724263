.banner-content {
  .ant-divider {
    border-top-color: #b1b1b1;
    margin: 10px 0;
  }
  .btn-submit-form {
    width: 400px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    font-size: 20px;
  }
  .btn-show {
    text-align: center;
    height: 25px;
    border-radius: 10px;
    padding: 0 40px;
    font-size: 14px;
    line-height: 25px;

    &.default {
      color: #005d7a;
      background: white;
      border-color: #005d7a;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #e62783;
    border-color: #e62783;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: #e62783;
  }
  .ant-checkbox-group-item {
    margin-right: 0;
  }
  .input-quantity {
    width: calc(100% - 75px);
  }
  .input-unit {
    position: absolute;
    right: 10px;
  }

  .avatar-uploader {
    width: 100%;
    height: 100%;
  }

  .upload-button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .avatar-uploader > .ant-upload {
    width: 500px;
    height: 280px;
    position: relative;
    border: 1px solid #b1b1b1;
  }

  .image-banner.ant-image-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .image-banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .form-item-hidden {
    .ant-form-item-label {
      height: 0;
      visibility: hidden;
    }
    .ant-form-item-control-input {
      display: none;
    }
  }
  .ant-table-cell {
    min-width: 100px;
  }

  .img-banner-detail {
    object-fit: cover;
    object-position: center;
  }
}
.blush-form .ant-select .ant-select-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.btn-action {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.btn-banner-submit {
  outline: none;
  margin-top: 40px;
  width: 150px;
  height: 36px;
  background-color: #606060;
  color: #ffffff;
  border-radius: 5px;
}
.btn-banner-cancel {
  outline: none;
  margin-top: 40px;
  width: 150px;
  height: 36px;
  border-radius: 5px;
}
.ant-btn-primary {
  border-color: transparent !important;
}
