.div-box-forgot-password {
  padding: 60px 40px 70px 40px;
  background: white;
  box-shadow: 4px 4px 3px grey!important;
  color: #337d95;
  font-size: var(--fs);
  --max-fs: 16;
  --min-fs: 14;

  .redirect-page a {
    font-size: var(--fs);
    --max-fs: 18;
    --min-fs: 16;
    color: #4d4d4d;
    text-decoration: underline !important;
  }
}

.content-error {
  .title-error {
    color: red;
    margin-bottom: 20px;
    font-size: var(--fs);
    --max-fs: 20;
    --min-fs: 16;
  }

  p {
    font-size: var(--fs);
    --max-fs: 16;
    --min-fs: 14;
    color: #337d95;
  }
}