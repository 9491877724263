.master-data-index {
  .content-wrapper {
    margin-top: 80px;
  }
  .title-page {
    color: #d14c87;
    font-size: 24px;
    margin-bottom: 20px;
  }
  .button-master-data {
      padding: 15px;
      border: 1px solid #337d95;
      display: block;
      text-align: center;
      margin-bottom: 10px;
      border-radius: 5px;
      color: #4d4d4d;
      font-size: 16px;
      min-height: 57px;

      &.size-small {
        font-size: 15px;
      }

      &:hover, &:focus, &:visited {
        box-shadow: 0 0 8px #e62783;
        margin-left: -15px;
        margin-right: 15px;
      }
  }
}

.modal-form-master-data {
  .ant-modal-title {
    color: #337d95;
    font-size: 20px;
  }
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    text-align: center;

    & > label {
      color: #d14c87;
      font-size: 15px;
    }

    & > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      content: '';
    }
  }
  .btn-submit-form {
    width: 300px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    font-size: 20px;
  }

  input, .ant-select .ant-select-selector {
    background: #f0f0f0;
    color: #808080;
    height: 25px;
    border-radius: 5px;
    text-align: center;

    .ant-select-selection-placeholder, .ant-select-selection-item {
      line-height: 25px;
    }
  }
}

.form-mapping-worries {
  color: #808080;

  .ant-checkbox-wrapper {
    display: flex;
    color: #808080;
    flex-direction: row-reverse;
  }
  .ant-form-item-label > label {
    color: #337d95;
    height: auto;
  }
  .row-worries {
    flex-direction: column;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #e62783;
    border-color: #e62783;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, 
  .ant-checkbox:hover .ant-checkbox-inner, 
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: #e62783;
  }
  .ant-checkbox-group-item {
    margin-right: 0;
    span{
      white-space: nowrap;
    }
  }
}
.detail-value {
  color: #808080;
}

input.ant-select-selection-search-input {
  height: 25px!important;
}

.dynamic-delete-button {
  position: relative;
  margin: 0 4px;
  color: #999999;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.modal-form-mapping-worries {
  .ant-modal-body {
    max-height: 80vh;
    overflow: auto;
  }
  .ant-modal-footer {
    text-align: center;
  }

  .ant-modal-title {
    color: #337d95;
    font-size: 20px;
  }
}