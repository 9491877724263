.header {
  padding: 0px 25px;
  background: #ffffff;
  opacity: 0.8;
  height: 100px;
  box-shadow: 0px 3px 5px #808080;
  .icon-login {
    padding-right: 15px;
  }
}
.head-shadow {
  box-shadow: 0px 3px 6px 0px #e62783;
}
.header_other {
  background: #467c92;
  height: 65px;

  .logo {
    width: 185px;
    text-align: center;
    img {
      width: 70px;
    }
  }
}
.sidebar_menu {
  background: #467c92;
  width: 185px;
  min-height: calc(100vh - 65px);
  flex-shrink: 0;
  position: fixed;
  left: 0;
  .list_menu {
    border-top: 1px solid #ffffff;
    margin-left: 6px;
    margin-right: 12px;
    ul {
      li {
        padding: 16px 0px;
        padding-left: 23px;
        a {
          color: #ffffff;
        }
        .icon {
          margin-right: 22px;
          img {
            min-width: 20px;
          }
        }
        &.active {
          &:before {
            position: absolute;
            content: ' ';
            width: 100%;
            height: 32px;
            background: #ffffff;
            top: 13px;
            left: 15px;
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
            z-index: -1;
          }
          a {
            color: #d14c87;
          }
        }
      }
    }
  }
}
#app {
  background: #467c92;
}
.sticky {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
}

.box-header-username {
  background: #ffffff;
  width: calc(100% - 205px);
  margin-left: auto;
  margin-right: 17px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  margin-top: 17px;
}
// .header-sticky {
//   z-index: 9999;
// }
