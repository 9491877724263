.username {
  padding: 13px 0px;
  border-bottom: 1px solid #467c92;
  margin: 0px 45px 0px 15px;
  position: sticky;
  top: 20px;
  .icon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #d14c87;
    margin-right: 20px;
  }
  .u_name h4 {
    font-size: 20px;
    color: #d14c87;
    margin: 0;
    line-height: 1;
  }
}

.page-content {
  @include header_top;
  width: calc(100% - 205px);
  margin-left: auto;
  margin-right: 17px;
  min-height: calc(100vh - 65px);

  .content-wrapper {
    padding: 0 20px 32px 20px;
  }
  .content-wrapper-has-padding-top {
    padding-top: 32px;
  }
}

.page-common-content {
  .title-page {
    color: #467c92;
    font-size: 22px;
    .back-to-master {
      color: #467c92;
      margin-right: 20px;
      span.anticon {
        vertical-align: 0;
      }
    }
    margin-bottom: 0;
  }
  .btn-add-master-data {
    border-radius: 5px;
    background: #d14c87;
    border: 1px solid #d14c87;
    color: #ffffff;
    padding: 0px 30px;
    height: 40px;
    line-height: 40px;
  }
  .div-master-data-table {
    .ant-table-body table {
      border-collapse: separate;
      border-spacing: 0 10px;
      margin-top: -10px;
    }

    table {
      td {
        border-bottom: 1px solid #f1f1f1;
        border-top: 1px solid #f1f1f1;
        font-size: 12px;
      }
      tr > td:first-child {
        border-left: 1px solid #f1f1f1;
        color: #d14c87;
      }
      tr > td:last-child {
        border-right: 1px solid #f1f1f1;
      }

      thead {
        & tr:first-child {
          height: 30px;
        }
        tr > th:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        tr > th:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
        tr > th {
          padding: 0;
          text-align: center;
          background: #808080;
          color: white;
          font-size: 12px;
        }
      }

      tbody {
        td {
          padding: 10px;
          color: #808080;
          font-size: 12px;
          white-space: nowrap;

          &.allow-wrap {
            white-space: normal;
          }

          .id-col {
            color: #d14c87;
          }
        }
      }

      tbody tr:hover {
        box-shadow: 0 0 8px #e62783;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &.row-pagination {
          box-shadow: none;
        }

        td {
          background: white;
        }
      }

      th.ant-table-column-has-sorters:hover {
        background-color: #808080;
      }

      .btn-action {
        width: 100px;
        text-align: center;
        height: 20px;
        border-radius: 10px;
        padding: 0;
        font-size: 10px;

        &.primary {
          color: white;
          background: #337d95;
          border-color: #337d95;
        }
        &.default {
          color: #808080;
          background: white;
          border-color: #337d95;
        }
      }
    }
    .row-pagination {
      border: none !important;
      padding: 0;

      ul.pagination {
        justify-content: center;
        margin-bottom: 0;

        li {
          padding: 0 var(--space);
          --max-sp: 25;
          --min-sp: 10;

          a {
            color: #e62783;
          }
          &.active a {
            color: #4d4d4d;
          }
        }
      }
    }
  }
  .div-master-data-rowspan {
    table {
      tr.row-parent > td:first-child {
        border: none !important;
      }
      tr.row-parent > td:nth-child(2) {
        border-left: 1px solid #f1f1f1;
      }
      .col-parent {
        vertical-align: top;
        padding-top: 0;
        padding-left: 0;
      }

      tbody tr:hover {
        box-shadow: none;

        td {
          background: white;
        }
      }
      tbody .sub-worrie-item {
        color: #e62783 !important;
      }
    }
    .button-parent-category {
      color: #337d95;
      background: white;
      border: 1px solid #337d95;
      padding: 15px 8px;
      border-radius: 5px;
      width: 95%;
      white-space: normal;

      &:hover {
        box-shadow: 0 0 8px #e62783;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.modal_search_reservation {
  .ant-modal-header {
    border-bottom: none;
    .ant-modal-title {
      color: #5d96a9;
    }
  }
}
.blush-form {
  position: relative;
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    text-align: center;

    & > label {
      color: #d14c87;
      font-size: 15px;
    }

    &
      > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
      )::before {
      content: '';
    }
  }

  input,
  .ant-select .ant-select-selector {
    background: #f0f0f0 !important;
    color: #808080;
    height: 25px;
    border-radius: 5px;
    text-align: center;
    border: 1px solid #f0f0f0;

    &:hover,
    &:focus {
      border: 1px solid #f0f0f0;
    }
    &:focus {
      box-shadow: none;
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      line-height: 25px;
    }
  }
  .detail-value {
    color: #808080;
  }
  .ant-picker-range,
  .ant-picker {
    padding: 0;
    border: none;
    width: 100%;
    .ant-picker-active-bar {
      background-color: #e62783;
      margin-left: 0;
    }
    .ant-picker-separator {
      height: 25px;
    }
  }
  .ant-picker:hover,
  .ant-picker-focused {
    border: none;
    box-shadow: none;
  }
}
form.ant-form {
  .ant-form-item-explain {
    font-size: 10px;
    text-align: left;
  }
}

.btn-blush {
  overflow: hidden;
  position: relative;
  display: inline-block;
  border-radius: 3px;
  cursor: pointer;

  .button_su_inner {
    display: inline-block;
    background: #d14c87;
    color: #f4f4f4;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    transition: 400ms;
    text-decoration: none;
    border-radius: 5px;
    padding: 12px 40px;
    z-index: 100000;

    &.button-large {
      min-width: 150px;
    }
  }

  .button_text_container {
    position: relative;
    z-index: 990;
  }
}

.btn-background-pink {
  .button_su_inner {
    background: #d14c87;
  }
}

.btn-background-default {
  .button_su_inner {
    background: #fff;
    color: #000;
    border: 1px solid #000;
  }
}

.button-default {
  height: 40.8px;
  &:hover {
    transform: scale(0.9);
  }
}

.btn-background-dark {
  .button_su_inner {
    background: #337d95;
    min-width: 450px;
  }
}

.btn-show-outline {
  text-align: center;
  height: 25px;
  border-radius: 10px;
  padding: 0 40px;
  font-size: 14px;
  line-height: 25px;

  &.default {
    color: #808080 !important;
    background: white;
    border-color: #005d7a;
  }

  &.ant-btn-icon-only {
    width: auto;
  }
}

.div-input-group-suffix {
  text-align: left;
  input {
    width: calc(100% - 30px);
  }

  input.full-width {
    width: 100%;
  }

  .ant-btn-icon-only {
    float: right;
    width: 25px;
    height: 25px;
    color: #808080 !important;
    background: white;
    border-color: #005d7a;
    border-radius: 3px;
  }
}

.ant-input-affix-wrapper {
  padding: 0;
  border: none;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border: none;
  box-shadow: none;
}

.master-data-header {
  .ant-row:first-child {
    max-height: 100% !important;
  }
}

.box-header-sticky {
  position: sticky;
  padding: 32px 0 20px;
  background: white;
  left: 0;
  top: 65px;
  z-index: 999;

  .ant-row:first-child {
    max-height: 46px;
  }
}

input:focus::placeholder {
  color: transparent;
}

.border-none {
  border: none;
}

.text-align-center {
  text-align: center;
}

.formInput {
  margin: 0;
  padding: 19.2px 0;

  .ant-form-item-label {
    label {
      color: #d14c87;
    }
  }

  .ant-form-item-control {
    input {
      height: 20px;
      background-color: #f0f0f0 !important;
      color: #808080;
      border: none;
      border-radius: 4px;
    }

    &:focus {
      outline: none;
    }

    .ant-select {
      width: 100% !important;

      .ant-select-selector {
        height: 20px;
        background-color: #f0f0f0 !important;
        border: none;
        border-radius: 4px;

        .ant-select-selection-item {
          color: #808080;
          line-height: 20px;
        }
      }
    }

    .ant-radio-group {
      display: flex;
      justify-content: space-around;
      .ant-radio-input {
        height: 12px;
        width: 12px;
      }

      .ant-radio-inner {
        background-color: #f0f0f0;
        border: none;

        &::after {
          top: 8px;
          left: 8px;
          background-color: #e62783;
        }
      }

      .ant-radio-wrapper {
        span {
          color: #808080;
        }
      }
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid #b1b1b1;
  }
}

.phoneInput {
  .ant-form-item-control-input {
    margin: 0 -10px;
  }

  .ant-form-item-control-input-content {
    display: flex;

    .phoneInputContent {
      width: calc(100% / 3);
      margin-bottom: 0;
      padding: 0 20px;

      .ant-form-item-control {
        input {
          height: 20px;
          background-color: #f0f0f0;
          color: #808080;
          border: none;
          border-radius: 4px;
        }
      }
    }
  }

  .strike {
    height: 2px;
    width: 10px;
    background: #808080;
    margin: auto;
  }
}

.passwordInput {
  position: relative;

  .iconViewPass {
    position: absolute;
    top: 22px;
    right: 10px;
    cursor: pointer;
  }
}

.blc-button-animation {
  transition: all 0.3s;
}

.blc-button-animation:hover,
.blc-button-animation:active {
  transform: scale(0.9);
  background-color: #337b95 !important;
  border: none !important;

  a {
    background-color: #337b95;
  }
}

.blc-button-animation-pink {
  transition: all 0.3s;
}

.blc-button-animation-pink:hover,
.blc-button-animation-pink:active {
  transform: scale(0.9);
  background-color: #d14c87 !important;
  border: none !important;

  a {
    background-color: #d14c87;
  }
}

.ant-form-item-extra {
  font-size: 10px !important;
  color: #ff4d4f;
}

.blush-ellipsis {
  white-space: pre-line;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  word-break: break-all;
}
