.timeTable-booking {
  user-select: none;
  --max-sp: 60;
  --min-sp: 10;
  --max-h: 100;
  --min-h: 30;
}
.test {
  background-color: #000;
}
@mixin borderRadiusTop {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
@mixin borderRadiusBottom {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.bg-booking-active {
  background: #e62783;
  @include borderRadiusTop;
}
.bg-booking-idle-active {
  background: #337d95;
  @include borderRadiusTop;
  @include borderRadiusBottom;
}

.bg-booking-active-bonus {
  background: #ff5fb3;
  @include borderRadiusBottom;
}

.bg-inline {
  right: 15px;
}
.bg-change-booking-active {
  border: 4px solid #e62783;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.bg-change-booking-active-bonus {
  border: 4px solid #e62783;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.bg-booking-old-active {
  background: rgba(178, 178, 178, 0.8);
  @include borderRadiusTop;
  z-index: 9999;
}
.bonus-time {
  @include borderRadiusBottom;
  background: #b2b2b2;
  opacity: 0.6;
}
.bg-booking {
  position: absolute;
  width: 60%;
  height: calc(100% + 40px);
  background: #f1f1f1;
  right: 0;
  top: -20px;
}
.bg-booking-idle {
  position: absolute;
  width: 15px;
  height: calc(100% + 40px);
  background: #f1f1f1;
  left: 30px;
  top: -20px;
}

.time-line {
  span {
    color: #808080;
  }
  .opacity-0 {
    opacity: 0;
  }
}
.line-chart {
  width: 100%;
  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    height: 2px;
    width: 100%;
    right: 0;
    background: #000000;
  }
}
.sub_menu {
  z-index: 999;
  .time-line {
    span {
      color: #808080;
    }
  }
  .line-chart {
    &:before {
      height: 1px;
      width: calc(100% - 8px);
    }
  }
}
.time-line {
  line-height: 1;
  top: -9px;
  span {
    font-size: 10px;
    color: #4d4d4d;
  }
}
.title-counselor-name {
  font-size: 30px;
  color: #e62783;
  margin-bottom: 35px;
}
