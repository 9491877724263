.coupon-content {
  .ant-divider {
    border-top-color: #b1b1b1;
    margin: 10px 0;
  }
  .btn-submit-form {
    width: 400px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    font-size: 20px;
  }
  .btn-show {
    text-align: center;
    height: 25px;
    border-radius: 10px;
    padding: 0 40px;
    font-size: 14px;
    line-height: 25px;

    &.default {
      color: #005d7a;
      background: white;
      border-color: #005d7a;
    }
    &.btn-pink {
      background: #e62783;
      color: white;
      border: none;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #e62783;
    border-color: #e62783;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: #e62783;
  }
  .ant-checkbox-group-item {
    margin-right: 0;
  }
  .input-quantity {
    width: calc(100% - 75px);
  }
  .input-unit {
    position: absolute;
    right: 10px;
  }
}

.wrapper-input-unit {
  position: relative;
  .input-unit {
    position: absolute;
    right: 10px;
    z-index: 1;
    &.disabled {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}
.modal-form-master-data .ant-select .ant-select-selector {
  height: auto !important;
  max-height: 100px;
  overflow: auto;
}
.modal-confirm {
  .ant-modal-body {
    text-align: center;

    p {
      color: #808080;
      font-size: 16px;
    }
  }
}
.div-table-user-used-coupon table {
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-top: -10px;
}
