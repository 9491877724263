@font-face {
  font-family: Avenir;
  src: url(../assets/font/Avenir.ttc);
}
@font-face {
  font-family: koz;
  src: url(../assets/font/KozGoPr6N-Regular.otf);
}
@font-face {
  font-family: ShueiGoKinStdB;
  src: url(../assets/font/A-OTF-ShueiGoKinStd-B.otf);
}
@font-face {
  font-family: ShueiGoKinStdL;
  src: url(../assets/font/A-OTF-ShueiGoKinStd-L.otf);
}
@import '~antd/dist/antd.css';
* {
  --fs: calc(
    (var(--min-fs) * 1px) + (var(--max-fs) - var(--min-fs)) *
      ((100vw - 320px) / var(--window-width))
  );
  --height: calc(
    (var(--min-h) * 1px) + (var(--max-h) - var(--min-h)) *
      ((100vw - 320px) / var(--window-width))
  );
  --space: calc(
    (var(--min-sp) * 1px) + (var(--max-sp) - var(--min-sp)) *
      ((100vw - 320px) / var(--window-width))
  );
}
:root {
  --window-width: 1600;
}
body {
  font-family: koz;
}
.container-1100 {
  max-width: 1100px;
  margin: auto;
}
.container-1250 {
  max-width: 1250px;
  margin: auto;
}
.container-1000 {
  max-width: 1000px;
  margin: auto;
}
.container-920 {
  max-width: 920px;
  margin: auto;
}
.container-970 {
  max-width: 970px;
  margin: auto;
}
.container-660 {
  width: 660px;
  margin: auto;
}
.container-700 {
  width: 700px;
  margin: auto;
}
.color-text-1 {
  color: #b2b2b2;
}
.banner-content {
  background: white;
}
.zi__9 {
  z-index: 9;
}
.w-lg-service-1 {
  max-width: var(--fs);
  --max-fs: 370;
  --min-fs: 200;
  width: 100%;
  margin: auto;
}
.w-lg-service-2 {
  width: 254px;
  margin: auto;
}
.fs__33 {
  font-size: 33px;
}
a {
  text-decoration: none !important;
  color: #000000;
}
.flex-derection-column {
  flex-direction: column;
}
label {
  margin-bottom: 0px;
}
.flex-1 {
  flex: 1;
}
.p__60 {
  padding: 0px 60px;
}
.pl__10 {
  padding-left: 10px;
}
.pr__10 {
  padding-right: 10px;
}
.pl__20 {
  padding-left: 20px;
}
.pr__20 {
  padding-right: 20px;
}
.pl__30 {
  padding-left: 30px;
}
.pr__30 {
  padding-right: 30px;
}
.outline-none {
  outline: none;
}
.cursor {
  cursor: pointer;
}
@media (min-width: 992px) {
  .container {
    max-width: 100%;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1366px;
    width: 100%;
    padding: 0px !important;
  }
}
@media (max-width: 991px) {
  .breadcrumbs {
    display: none !important;
  }
  .container {
    max-width: 100%;
  }
}
@media (min-width: 800px) {
  .container-800 {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
  }
}
@media (max-width: 849px) {
  .container-800 {
    max-width: 100%;
    padding: 0px var(--fs);
    --max-fs: 80;
    --min-fs: 10;
  }
}
.mt__0 {
  margin-top: 0px;
}
.mt__5 {
  margin-top: 5px;
}
.mt__10 {
  margin-top: 10px;
}
.mt__15 {
  margin-top: 15px;
}
.mt__20 {
  margin-top: 20px;
}
.mt__25 {
  margin-top: 25px;
}
.mt__30 {
  margin-top: 30px;
}
.mt__35 {
  margin-top: 35px;
}
.mt__40 {
  margin-top: 40px;
}
.mt__45 {
  margin-top: 45px;
}
.mt__50 {
  margin-top: 50px;
}
.mt__55 {
  margin-top: 55px;
}
.mt__60 {
  margin-top: 60px;
}
.mt__65 {
  margin-top: 65px;
}
.mt__70 {
  margin-top: 70px;
}
.mt__75 {
  margin-top: 75px;
}
.mt__80 {
  margin-top: 80px;
}
.mt__85 {
  margin-top: 85px;
}
.mt__90 {
  margin-top: 90px;
}
.mt__95 {
  margin-top: 95px;
}
.mt__100 {
  margin-top: 100px;
}

.mb__0 {
  margin-bottom: 0px;
}
.mb__5 {
  margin-bottom: 5px;
}
.mb__10 {
  margin-bottom: 10px;
}
.mb__15 {
  margin-bottom: 15px;
}
.mb__20 {
  margin-bottom: 20px;
}
.mb__25 {
  margin-bottom: 25px;
}
.mb__30 {
  margin-bottom: 30px;
}
.mb__35 {
  margin-bottom: 35px;
}
.mb__40 {
  margin-bottom: 40px;
}
.mb__45 {
  margin-bottom: 45px;
}
.mb__50 {
  margin-bottom: 50px;
}
.mb__55 {
  margin-bottom: 55px;
}
.mb__60 {
  margin-bottom: 60px;
}
.mb__65 {
  margin-bottom: 65px;
}
.mb__70 {
  margin-bottom: 70px;
}
.mb__75 {
  margin-bottom: 75px;
}
.mb__80 {
  margin-bottom: 80px;
}
.mb__85 {
  margin-bottom: 85px;
}
.mb__90 {
  margin-bottom: 90px;
}
.mb__95 {
  margin-bottom: 95px;
}
.mb__100 {
  margin-bottom: 100px;
}

.mr__0 {
  margin-right: 0px;
}
.mr__5 {
  margin-right: 5px;
}
.mr__10 {
  margin-right: 10px;
}
.mr__15 {
  margin-right: 15px;
}
.mr__20 {
  margin-right: 20px;
}
.mr__25 {
  margin-right: 25px;
}
.mr__30 {
  margin-right: 30px;
}
.mr__35 {
  margin-right: 35px;
}
.mr__40 {
  margin-right: 40px;
}
.mr__45 {
  margin-right: 45px;
}
.mr__50 {
  margin-right: 50px;
}
.mr__55 {
  margin-right: 55px;
}
.mr__60 {
  margin-right: 60px;
}
.mr__65 {
  margin-right: 65px;
}
.mr__70 {
  margin-right: 70px;
}
.mr__75 {
  margin-right: 75px;
}
.mr__80 {
  margin-right: 80px;
}
.mr__85 {
  margin-right: 85px;
}
.mr__90 {
  margin-right: 90px;
}
.mr__95 {
  margin-right: 95px;
}
.mr__100 {
  margin-right: 100px;
}

.ml__0 {
  margin-left: 0px;
}
.ml__5 {
  margin-left: 5px;
}
.ml__10 {
  margin-left: 10px;
}
.ml__15 {
  margin-left: 15px;
}
.ml__20 {
  margin-left: 20px;
}
.ml__25 {
  margin-left: 25px;
}
.ml__30 {
  margin-left: 30px;
}
.ml__35 {
  margin-left: 35px;
}
.ml__40 {
  margin-left: 40px;
}
.ml__45 {
  margin-left: 45px;
}
.ml__50 {
  margin-left: 50px;
}
.ml__55 {
  margin-left: 55px;
}
.ml__60 {
  margin-left: 60px;
}
.ml__65 {
  margin-left: 65px;
}
.ml__70 {
  margin-left: 70px;
}
.ml__75 {
  margin-left: 75px;
}
.ml__80 {
  margin-left: 80px;
}
.ml__85 {
  margin-left: 85px;
}
.ml__90 {
  margin-left: 90px;
}
.ml__95 {
  margin-left: 95px;
}
.ml__100 {
  margin-left: 100px;
}

.pt__0 {
  padding-top: 0px;
}
.pt__5 {
  padding-top: 5px;
}
.pt__10 {
  padding-top: 10px;
}
.pt__15 {
  padding-top: 15px;
}
.pt__20 {
  padding-top: 20px;
}
.pt__25 {
  padding-top: 25px;
}
.pt__30 {
  padding-top: 30px;
}
.pt__35 {
  padding-top: 35px;
}
.pt__40 {
  padding-top: 40px;
}
.pt__45 {
  padding-top: 45px;
}
.pt__50 {
  padding-top: 50px;
}
.pt__55 {
  padding-top: 55px;
}
.pt__60 {
  padding-top: 60px;
}
.pt__65 {
  padding-top: 65px;
}
.pt__70 {
  padding-top: 70px;
}
.pt__75 {
  padding-top: 75px;
}
.pt__80 {
  padding-top: 80px;
}
.pt__85 {
  padding-top: 85px;
}
.pt__90 {
  padding-top: 90px;
}
.pt__95 {
  padding-top: 95px;
}
.pt__100 {
  padding-top: 100px;
}
.pt__bg {
  padding-top: 50%;
}

.pb__0 {
  padding-bottom: 0px;
}
.pb__5 {
  padding-bottom: 5px;
}
.pb__10 {
  padding-bottom: 10px;
}
.pb__15 {
  padding-bottom: 15px;
}
.pb__20 {
  padding-bottom: 20px;
}
.pb__25 {
  padding-bottom: 25px;
}
.pb__30 {
  padding-bottom: 30px;
}
.pb__35 {
  padding-bottom: 35px;
}
.pb__40 {
  padding-bottom: 40px;
}
.pb__45 {
  padding-bottom: 45px;
}
.pb__50 {
  padding-bottom: 50px;
}
.pb__55 {
  padding-bottom: 55px;
}
.pb__60 {
  padding-bottom: 60px;
}
.pb__65 {
  padding-bottom: 65px;
}
.pb__70 {
  padding-bottom: 70px;
}
.pb__75 {
  padding-bottom: 75px;
}
.pb__80 {
  padding-bottom: 80px;
}
.pb__85 {
  padding-bottom: 85px;
}
.pb__90 {
  padding-bottom: 90px;
}
.pb__95 {
  padding-bottom: 95px;
}
.pb__100 {
  padding-bottom: 100px;
}

.pr__0 {
  padding-right: 0px;
}
.pr__5 {
  padding-right: 5px;
}
.pr__12 {
  padding-right: 12px;
}
.pr__10 {
  padding-right: 10px;
}
.pr__15 {
  padding-right: 15px;
}
.pr__20 {
  padding-right: 20px;
}
.pr__25 {
  padding-right: 25px;
}
.pr__30 {
  padding-right: 30px;
}
.pr__35 {
  padding-right: 35px;
}
.pr__40 {
  padding-right: 40px;
}
.pr__45 {
  padding-right: 45px;
}
.pr__50 {
  padding-right: 50px;
}
.pr__55 {
  padding-right: 55px;
}
.pr__60 {
  padding-right: 60px;
}
.pr__65 {
  padding-right: 65px;
}
.pr__70 {
  padding-right: 70px;
}
.pr__75 {
  padding-right: 75px;
}
.pr__80 {
  padding-right: 80px;
}
.pr__85 {
  padding-right: 85px;
}
.pr__90 {
  padding-right: 90px;
}
.pr__95 {
  padding-right: 95px;
}
.pr__100 {
  padding-right: 100px;
}

.pl__0 {
  padding-left: 0px;
}
.pl__5 {
  padding-left: 5px;
}
.pl__10 {
  padding-left: 10px;
}
.pl__12 {
  padding-left: 12px;
}
.pl__15 {
  padding-left: 15px;
}
.pl__20 {
  padding-left: 20px;
}
.pl__25 {
  padding-left: 25px;
}
.pl__30 {
  padding-left: 30px;
}
.pl__35 {
  padding-left: 35px;
}
.pl__40 {
  padding-left: 40px;
}
.pl__45 {
  padding-left: 45px;
}
.pl__50 {
  padding-left: 50px;
}
.pl__55 {
  padding-left: 55px;
}
.pl__60 {
  padding-left: 60px;
}
.pl__65 {
  padding-left: 65px;
}
.pl__70 {
  padding-left: 70px;
}
.pl__75 {
  padding-left: 75px;
}
.pl__80 {
  padding-left: 80px;
}
.pl__85 {
  padding-left: 85px;
}
.pl__90 {
  padding-left: 90px;
}
.pl__95 {
  padding-left: 95px;
}
.pl__100 {
  padding-left: 100px;
}

.p__5 {
  padding: 5px;
}
.p__10 {
  padding: 10px;
}
.p__15 {
  padding: 15px;
}
.p__20 {
  padding: 20px;
}
.p__25 {
  padding: 25px;
}
.p__30 {
  padding: 30px;
}

.fs__0 {
  font-size: 0px;
}
.fs__1 {
  font-size: 1px;
}
.fs__2 {
  font-size: 2px;
}
.fs__3 {
  font-size: 3px;
}
.fs__4 {
  font-size: 4px;
}
.fs__5 {
  font-size: 5px;
}
.fs__6 {
  font-size: 6px;
}
.fs__7 {
  font-size: 7px;
}
.fs__8 {
  font-size: 8px;
}
.fs__9 {
  font-size: 9px;
}
.fs__10 {
  font-size: 10px;
}
.fs__11 {
  font-size: 11px;
}
.fs__12 {
  font-size: 12px;
}
.fs__13 {
  font-size: 13px;
}
.fs__14 {
  font-size: 14px;
}
.fs__15 {
  font-size: 15px;
}
.fs__16 {
  font-size: 16px;
}
.fs__17 {
  font-size: 17px;
}
.fs__18 {
  font-size: 18px;
}
.fs__19 {
  font-size: 19px;
}
.fs__20 {
  font-size: 20px;
}
.fs__21 {
  font-size: 21px;
}
.fs__22 {
  font-size: 22px;
}
.fs__23 {
  font-size: 23px;
}
.fs__24 {
  font-size: 24px;
}
.fs__25 {
  font-size: 25px;
}
.fs__26 {
  font-size: 26px;
}
.fs__27 {
  font-size: 27px;
}
.fs__28 {
  font-size: 28px;
}
.fs__29 {
  font-size: 29px;
}
.fs__30 {
  font-size: 30px;
}
.fs__31 {
  font-size: 31px;
}
.fs__32 {
  font-size: 32px;
}
.fs__33 {
  font-size: 33px;
}
.fs__34 {
  font-size: 34px;
}
.fs__35 {
  font-size: 35px;
}
.fs__36 {
  font-size: 36px;
}
.fs__37 {
  font-size: 37px;
}
.fs__38 {
  font-size: 38px;
}
.fs__39 {
  font-size: 39px;
}
.fs__40 {
  font-size: 40px;
}
.fs__41 {
  font-size: 41px;
}
.fs__42 {
  font-size: 42px;
}
.fs__43 {
  font-size: 43px;
}
.fs__44 {
  font-size: 44px;
}
.fs__45 {
  font-size: 45px;
}
.fs__46 {
  font-size: 46px;
}
.fs__47 {
  font-size: 47px;
}
.fs__48 {
  font-size: 48px;
}
.fs__49 {
  font-size: 49px;
}
.fs__50 {
  font-size: 50px;
}
.fs__51 {
  font-size: 51px;
}
.fs__52 {
  font-size: 52px;
}
.fs__53 {
  font-size: 53px;
}
.fs__54 {
  font-size: 54px;
}
.fs__55 {
  font-size: 55px;
}
.fs__56 {
  font-size: 56px;
}
.fs__57 {
  font-size: 57px;
}
.fs__58 {
  font-size: 58px;
}
.fs__59 {
  font-size: 59px;
}
.fs__60 {
  font-size: 60px;
}
.fs__61 {
  font-size: 61px;
}
.fs__62 {
  font-size: 62px;
}
.fs__63 {
  font-size: 63px;
}
.fs__64 {
  font-size: 64px;
}
.fs__65 {
  font-size: 65px;
}
.fs__66 {
  font-size: 66px;
}
.fs__67 {
  font-size: 67px;
}
.fs__68 {
  font-size: 68px;
}
.fs__69 {
  font-size: 69px;
}
.fs__70 {
  font-size: 70px;
}
.fs__71 {
  font-size: 71px;
}
.fs__72 {
  font-size: 72px;
}
.fs__73 {
  font-size: 73px;
}
.fs__74 {
  font-size: 74px;
}
.fs__75 {
  font-size: 75px;
}
.fs__76 {
  font-size: 76px;
}
.fs__77 {
  font-size: 77px;
}
.fs__78 {
  font-size: 78px;
}
.fs__79 {
  font-size: 79px;
}
.fs__80 {
  font-size: 80px;
}
.fs__81 {
  font-size: 81px;
}
.fs__82 {
  font-size: 82px;
}
.fs__83 {
  font-size: 83px;
}
.fs__84 {
  font-size: 84px;
}
.fs__85 {
  font-size: 85px;
}
.fs__86 {
  font-size: 86px;
}
.fs__87 {
  font-size: 87px;
}
.fs__88 {
  font-size: 88px;
}
.fs__89 {
  font-size: 89px;
}
.fs__90 {
  font-size: 90px;
}
.fs__91 {
  font-size: 91px;
}
.fs__92 {
  font-size: 92px;
}
.fs__93 {
  font-size: 93px;
}
.fs__94 {
  font-size: 94px;
}
.fs__95 {
  font-size: 95px;
}
.fs__96 {
  font-size: 96px;
}
.fs__97 {
  font-size: 97px;
}
.fs__98 {
  font-size: 98px;
}
.fs__99 {
  font-size: 99px;
}
.fs__100 {
  font-size: 100px;
}
.lh__12 {
  line-height: 1.2;
}
.bg__cover {
  background-size: cover;
}
.bg__contain {
  background-size: contain;
}
.bg__nrp {
  background-repeat: no-repeat;
}
.bg-center {
  background-position: center;
}
.pa__center {
  position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
  .mb_md__30 {
    margin-bottom: 30px;
  }
}
.vs_on_hv {
  opacity: 0;
  visibility: hidden;
}
.fw__300 {
  font-weight: 300;
}
.fw__400 {
  font-weight: 400;
}
.fw__500 {
  font-weight: 500;
}
.fw__600 {
  font-weight: 600;
}
.fw__700 {
  font-weight: 700;
}
.fw__800 {
  font-weight: 800;
}
.fw__900 {
  font-weight: 900;
}
.w-max-content {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
}

.w-136 {
  width: 136px;
}

.ovf {
  overflow: hidden;
}
.zi__1 {
  z-index: 1;
}
.zi__2 {
  z-index: 2;
}
.zi__9 {
  z-index: 9;
}
.zi__99 {
  z-index: 99;
}
.radius-0 {
  border-radius: 0;
}
.border-0 {
  border-width: 0;
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-5 {
  border-width: 5px;
}
.transition_all {
  transition: all 0.3s ease-in-out;
}

.pull-right {
  float: right;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}

.p-relative {
  position: relative;
}

@mixin header_top {
  background: #ffffff;
}
@mixin button_link {
  font-size: var(--fs);
  --max-fs: 20;
  --min-fs: 14;
  background: #337d95;
  border-radius: 5px;
  max-width: 450px;
  width: 100%;
  height: 45px;
  color: white;
}
@mixin disable {
  .paginationDisabled {
    a {
      color: #4d4d4d;
      cursor: default;
    }
  }
}
@mixin pagination {
  margin-top: 10px;
  ul {
    li {
      list-style: none;
      padding: 0 var(--space);

      --max-sp: 25;
      --min-sp: 10;

      a {
        color: #e62783;
      }
    }

    .selected {
      a {
        color: #4d4d4d;
      }
    }
  }

  .pagination-list-1 {
    @include disable;
    li {
      a {
        color: #e62783;
      }

      &:nth-child(2) {
        a {
          color: #4d4d4d;
        }
      }
    }
  }

  .pagination-list-2 {
    @include disable;
    li {
      a {
        color: #e62783;
      }

      &:nth-last-child(2) {
        a {
          color: #4d4d4d;
        }
      }
    }
  }
}
.justify-content-end {
  justify-content: end;
}

.table-no-wrap {
  th,
  td {
    white-space: nowrap;
  }
}
