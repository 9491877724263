.username {
  padding: 13px 0px;
  border-bottom: 1px solid #467c92;
  margin: 0px 45px 0px 15px;
  .icon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #d14c87;
    margin-right: 20px;
  }
  .u_name h4 {
    font-size: 20px;
    color: #d14c87;
    margin: 0;
    line-height: 1;
  }
}
.list-counselor {
  @include header_top;
  width: calc(100% - 205px);
  margin-left: auto;
  margin-right: 17px;
  min-height: 100vh;
  .content_wrapper {
    padding: 0 20px 32px 20px;
    .ant-btn {
      border-radius: 5px;
      background: #d14c87;
      color: #ffffff;
      // padding: 0px 30px;
      padding: 12px 40px;
      font-size: 14px;
      height: 46px;
      transition: 400ms;
      // line-height: 40px;
    }
    .label_filter {
      font-size: 12px;
      padding-left: 20px;
    }
    .input_search {
      box-shadow: 0px 0px 6px #808080;
      border-radius: 5px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: var(--fs);
      --max-fs: 445;
      --min-fs: 200;
      input {
        border: none;
        height: 45px;
        width: 100%;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      .icon {
        background: #cf4b85;
        height: 45px;
        width: 45px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        flex-shrink: 0;
      }
    }
    .table {
      .scroll-bar {
        height: 10px;
        background: #f1f1f1;
        border-radius: 50px;
        .scroll-width {
          background: #808080;
          height: inherit;
          border-radius: 50px;
        }
      }
      .tr {
        color: #808080;
        font-size: 10px;
        padding: 0;
        height: 48px;
        border-bottom: 1px solid #f1f1f1;
      }
      .thead {
        background: #808080;
        border-radius: 5px;
        position: sticky;
        top: 169px;
        left: 0;
        z-index: 9;
        overflow: hidden;
        ul {
          height: 29px;
        }
        .tr {
          color: #ffffff;
          flex-shrink: 0;
          height: 30px;
          .sort {
            background: #ffffff;
            width: 15px;
            height: 15px;
            border-radius: 5px;
            margin-left: 25px;
          }
          span {
            font-size: 12px;
          }
          &:first-child {
            padding-left: 20px;
          }
        }
      }
      .tbody {
        .tr {
          padding: 0;
          height: 40.84px;
          border: 1px solid #f1f1f1;
          margin: 10px 0px;
          &:hover {
            box-shadow: 0 0 8px #e62783;
          }
          a {
            font-size: 12px;
            span {
              color: #808080;
            }
            span:first-child,
            span:nth-child(2) {
              color: #d14c87;
            }
          }
          .td {
            flex-shrink: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 0px 10px 0px 0px;
            height: inherit;
            line-height: inherit;
            white-space: nowrap;
            font-size: 12px;
            &:first-child {
              padding-left: 20px;
            }
          }
        }
      }
    }
  }
  .search_pagination {
    @include pagination;
    .active {
      a {
        color: #4d4d4d;
      }
    }
    a {
      font-size: 12px;
    }
  }
}
@mixin input {
  width: 150px;
  background: #f0f0f0;
  border-radius: 5px;
  border: none;
  min-height: 20px;
  font-size: 12px;
}
.counselor_filter_picker {
  @include input;
  width: 100%;
}
.ant-list-item {
  color: #808080;
}
.modal_counselor {
  width: 100% !important;
  max-width: 700px;
  .ant-modal-title {
    color: #337d95;
    font-size: 20px;
  }
  .filter_counselor {
    .list_filter {
      padding: 0px var(--space);
      --max-sp: 100;
      --min-sp: 0;
      li,
      .ant-select-selection-item {
        color: #808080;
        font-size: 15px;
        text-align: center;
      }
      .ant-picker-input {
        input {
          -webkit-text-fill-color: #808080;
          text-align: center;
        }
      }
      .label {
        color: #d14c87;
      }
      li {
        margin-bottom: 12px;
      }
    }

    .ant-select {
      width: 100%;
      .ant-select-selector {
        @include input;
        width: 100%;
      }
    }
  }
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    button {
      @include button_link;
    }
  }
}
.modal_approve {
  .ant-modal-header .ant-modal-title {
    color: #005d7a;
  }
  .ant-modal-body {
    color: #808080;
    text-align: center;
  }
}
.counselor_detailed {
  @include header_top;
  width: calc(100% - 205px);
  margin-left: auto;
  margin-right: 17px;
  .title p {
    color: #467c92;
    font-size: 22px;
    padding-left: 55px;
    padding-bottom: 15px;
    line-height: 1;
  }
  .content_wrapper {
    padding-bottom: 90px;
    .infor {
      color: #808080;
    }
    .counselor_infor_item {
      border-bottom: 1px solid #b1b1b1;
      min-height: 42px;
      color: #d14c87;
      font-size: 15px;
      .approve {
        border: none;
        color: #808080;
      }
      .resolve,
      .reject {
        min-height: auto;
        height: 20px;
        width: 150px;
        border-radius: 50px;
      }
      .resolve {
        color: #ffffff;
        background: #e62783;
        border: none;
        margin-right: 15px;
      }
      .reject {
        color: #005d7a;
        border: 1px solid #005d7a;
      }
      .ant-select-selection-item,
      .ant-select-arrow {
        color: #808080;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        text-align: center;
      }
      .ant-select-selector {
        @include input;
        height: 20px;
        min-height: auto;
      }
      &:nth-of-type(26) {
        border-bottom: none;
      }
      .btn-abstract {
        min-height: auto;
        color: #005d7a;
        border-radius: 50px;
        height: 20px;
        padding: 0px;
        width: 150px;
        line-height: 20px;
        border: 1px solid #005d7a;
        &.btn-pink {
          background: #e62783;
          color: white;
          border: none;
        }
        span {
          font-size: 15px !important;
          line-height: 1;
        }
      }
      span:not(.label) {
        font-size: 14px;
      }
    }
    .btn_submit button {
      @include button_link;
      margin-top: 50px;
    }
  }
}
.btn-cancel {
  color: #005d7a;
  border-radius: 50px;
  height: 20px;
  padding: 0px;
  width: 150px;
  line-height: 20px;
}
.btn-success {
  color: #ffffff;
  border-radius: 50px;
  height: 20px;
  padding: 0px;
  width: 150px;
  line-height: 20px;
  background: #337d95;
}
.search-result {
  overflow-y: auto;
  padding: 25px 0;
  max-height: 330px;
  position: relative;
  box-shadow: inset 0px 11px 8px -10px #686868,
    inset 0px -11px 8px -10px #686868 !important;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 5px;
  }
  .active {
    background: #337d95;
    color: #fff;
  }

  .tag-name-search {
    font-size: 34px;
    color: #e62783;
    margin-bottom: 30px;
    margin-left: 20px;
  }

  .filter-by-name {
    .active {
      color: white;
    }
    margin-bottom: 20px;
    display: flex;
    width: 20%;

    li {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      box-shadow: 0 3px 10px #808080 !important;
      height: 30px;
      line-height: var(--height);
      --max-h: 35;
      --min-h: 25;
      color: #808080;
      border-radius: 5px;
      padding: 0 var(--space);
      --max-sp: 15;
      --min-sp: 15;
      margin: 0 10px 20px 10px;
      font-size: var(--fs);
      --max-fs: 16;
      --min-fs: 12;
      button {
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }
    }
  }
}
.hidden-border-footer {
  .ant-modal-footer {
    border: none;
  }
}
.modal-introduction {
  width: 520px !important;
  .ant-modal-body {
    border: 1px solid #808080;
    max-width: calc(100% - 40px);
    margin: auto;
    margin-top: 10px;
    text-align: left;
  }
  .ant-modal-header {
    border: none;
  }
}
.top_filter {
  button span {
    font-size: 16px;
    font-family: ShueiGoKinStdB;
  }
}
.modal-kyc-counselor {
  width: 500px !important;

  .kyc-title {
    color: #e62783;
    font-size: 20px;
    margin-bottom: 20px;
    text-align: left;
  }
  .kyc-select {
    width: 100%;
    margin-bottom: 20px;
    pointer-events: none;
  }
}

.blc-checkbox {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #e62783;
    border-color: #e62783;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: #e62783;
  }
}
