.page-login {
  background: #f1f1f1;
  min-height: calc(100vh - 100px);
  .breadcrumbs {
    padding: 35px 0px 45px 125px;
    a,
    span {
      color: #e62783;
    }
  }
  .forgot-password {
    margin: 25px 0px 40px 0px;
    color: #337d95;
    text-decoration: underline !important;
    text-decoration-thickness: 2px !important;
  }
  .icon-pass {
    max-width: 19px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .message-error {
    font-size: 9px;
    margin-top: 10px;
    margin-bottom: var(--space) !important;
    --max-sp: -15;
    --min-sp: -5;
    color: red;
    line-height: 1;
    &:nth-of-type(2),
    &:nth-of-type(3) {
      display: none;
    }
  }
  .message-hidden {
    color: #808080 !important;
  }
  .title-outside p {
    font-size: var(--fs);
    --max-fs: 35;
    --min-fs: 18;
    color: #808080;
    margin: 75px 0px 25px 0px;
    line-height: 1;
  }
  .form-wrapper {
    box-shadow: 4px 4px 0px #808080;
    border-radius: 5px;
  }
  .form-item {
    .field-form {
      border-bottom: none;
      border-left: none;
      &:first-child {
        border-left: 1px solid #808080;
      }
      &:nth-child(2) {
        padding-left: 40px;
        padding-right: 40px;
      }
    }
    &:first-child {
      .field-form {
        &:nth-child(1) {
          border-top-left-radius: 5px;
        }
        &:nth-child(2) {
          border-top-right-radius: 5px;
        }
      }
    }
    &:last-child {
      .field-form {
        &:nth-child(1) {
          border-bottom-left-radius: 5px;
        }
        &:nth-child(2) {
          border-bottom-right-radius: 5px;
        }
      }
    }
  }
  .field-form {
    height: 75px;
    line-height: 75px;
    border: 1px solid #808080;
    background: white;
    label {
      color: #e62783;
      font-size: var(--fs);
      --max-fs: 20;
      --min-fs: 14;
      white-space: nowrap;
    }
    input {
      height: 25px;
      background: #f1f1f1;
      border-radius: 5px;
    }
  }
  .term {
    margin: 0 0px 15px 0px;
    span {
      font-size: var(--fs);
      --max-fs: 18;
      --min-fs: 13;
      color: #808080;
    }
    .checkbox {
      width: 20px;
      height: 20px;
      border: 1px solid #808080;
      border-radius: 5px;
      margin-right: 10px;
    }
    .checkbox.active:before {
      position: absolute;
      content: '';
      width: calc(100% - 5px);
      height: calc(100% - 5px);
      background: #e62783;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .btn-info {
    font-size: var(--fs);
    --max-fs: 20;
    --min-fs: 14;
    background: #337d95;
    border-radius: 5px;
    max-width: 450px;
    width: 100%;
    height: 45px;
  }
  .redirect-link {
    padding-bottom: var(--space);
    --max-sp: 170;
    --min-sp: 150;
    a {
      color: #808080;
      font-size: var(--fs);
      --max-fs: 18;
      --min-fs: 13;
      margin-top: 14px;
      display: inline-block;
      text-decoration: underline !important;
    }
  }
  @media (max-width: 991px) {
    .message-error {
      bottom: 3px;
    }
    .title-outside p {
      padding-top: 35px;
      justify-content: center;
      display: flex !important;
      &::before {
        background: transparent;
        opacity: 1;
      }
    }
    .form-wrapper {
      box-shadow: none;
    }
    .btn-submit {
      padding: 0px 30px;
    }
    .form-item {
      margin-bottom: 15px;
      .field-form {
        background: transparent !important;
        height: auto;
        line-height: 1;
        border: none;
        padding: 0px;
        &:nth-child(1) {
          padding-bottom: 10px;
          text-align: left !important;
        }
      }
      input {
        height: 40px;
        border: 1px solid #808080 !important;
      }
    }
  }
}
