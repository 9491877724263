.form-authority {
  margin-top: 80px;

  .ant-divider {
    border-top-color: #B1b1b1;
    margin: 10px 0;
  }
  
  .btn-show-permission {
    text-align: center;
      height: 25px;
      border-radius: 10px;
      padding: 0 40px;
      font-size: 14px;
      line-height: 25px;
      
      &.default {
        color: #005d7a;
        background: white;
        border-color: #005d7a;
      }
  }

  .btn-submit-form {
    width: 400px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    font-size: 20px;
  }
}
.modal-form-permission {
  .ant-modal-title {
    color: #337d95;
    font-size: 20px;
  }
  .ant-radio-wrapper {
    color: #808080;
  }
  .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: #808080;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #4d4d4d;
  }
  .ant-radio-inner::after {
    background-color: #4d4d4d;
    top: 6px;
    left: 6px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }
}