.page-sale {
  .sale-pagination {
    margin: auto;
  }
  .link {
    color: #e62783;
    text-decoration: underline!important;
    margin-right: 20px;
  }
  .select-time, .select-time .ant-select-selector {
    background: #467c92;
    color: white;
    height: 25px;
    border-radius: 20px;
    text-align: center;
    width: 120px;
    border: none;

    &:hover {
      border: none;
    }
    .ant-select-selection-placeholder, .ant-select-selection-item {
      line-height: 25px;
    }
    .ant-select-arrow {
      color: white;
    }
  }
  .group-box-sale {
    margin-top: 20px;
    padding-left: 100px;
    padding-right: 80px;
    padding-bottom: 20px;

    .box-sale {
      border: 1px solid #467c92;
      border-radius: 5px;
      padding: 10px 15px;
      text-align: center;

      .span-label {
        font-size: 16px;
        color: #4D4D4D;
      }
      .span-value {
        font-size: 20px;
        color: #e62783;
      }
    }
  }
  .group-chart-sale {
    margin-top: 20px;
    padding-bottom: 20px;
  }

  .sale-date-picker {
    color: #005d7a;
    background: #fff;
    border-color: #005d7a;
    border-radius: 10px;
    padding: 0;
    margin-left: 10px;
    margin-right: 10px;

    .ant-picker-input {
      input {
        padding-left: 10px;
        padding-right: 10px;
        color: #005d7a;
        text-align: center;
      }
    }
  }
  .btn-export-csv {
    background: #467c92;
    color: white;
    height: 25px;
    border-radius: 20px;
    text-align: center;
    width: 120px;
    border: none;
    padding: 5px 34px;
  }
}
.blc-graph {
  cursor: context-menu;
}