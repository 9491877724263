.top_filter {
  .label_filter {
    font-size: 12px;
    padding-left: 20px;
  }
  .input_search {
    box-shadow: 0px 0px 6px #808080;
    border-radius: 5px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: var(--fs);
    --max-fs: 445;
    --min-fs: 200;
    input {
      border: none;
      height: 45px;
      width: 100%;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    .icon {
      background: #cf4b85;
      height: 45px;
      width: 45px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      flex-shrink: 0;
    }
  }
}

.modal_search_user {
  .ant-modal-title {
    color: #337d95;
    font-size: 20px;
  }
  .ant-picker {
    width: 100%;
    padding: 0 15px 0 0;
    background: #f0f0f0;
    border-radius: 5px;
    text-align: center;
    border: 1px solid #f0f0f0;
  }
  .btn-submit-form {
    width: 400px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    font-size: 20px;
  }
}
.sortIcon {
  background: #ffffff;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  margin-left: 25px;
  display: inline-table;
  cursor: pointer;

  img.rotate {
    transform: rotate(180deg);
  }
}

.filterIcon {
  background: #ffffff;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  margin-right: 10px;
  display: inline-table;
  cursor: pointer;
}

.user-master-data-item {
  height: var(--height);
  line-height: var(--height);
  --min-h: 25;
  color: gray;
  box-shadow: 0 0 6px grey;
  border-radius: 5px;
  padding: 0 var(--space);
  --max-sp: 15;
  --min-sp: 10;
  margin: 0 10px 20px 10px;
  font-size: var(--fs);
  --max-fs: 16;
  --min-fs: 12;
  display: inline-block;
}

.worry-icon-color-item {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 9999px;
}

.worry-icon-color {
  display: flex;
}

.user-note-detail {
  color: gray;
  border: 1px solid #808080;
  border-radius: 5px;
  padding: 0 var(--space);
  --max-sp: 15;
  --min-sp: 10;
  margin: 0 10px 20px 10px;
  font-size: var(--fs);
  --max-fs: 16;
  --min-fs: 12;
  min-height: 130px;
}

.text-area-admin-note.ant-input {
  height: 260px;
  width: 350px;
  border-radius: 5px;
}

.text-area-admin-note.ant-input[disabled] {
  color: #0000008c;
}
